import { graphql } from "gatsby";
import * as React from "react";
import Errors from "../Components/Errors/Errors";
import Page from "../Components/Templates/Page";
// import Errors from "../Components/Errors/Errors";
export const query = graphql`
  query FrontpageQuery {
    page: sanityPage(
      _id: { eq: "185f93c7-1aa3-4d0c-9974-1c81896489d8" }
      slug: { current: {} }
    ) {
      _id
      meta_title
      meta_desc
      title
      _rawPagebuildersection
    }
    gallerypage: allSanityGallerypage {
      edges {
        node {
          _id
          cover_image {
            asset {
              _id
            }
          }
          title
          slug {
            current
          }
        }
      }
    }
    eventspage: allSanityEventspage {
      edges {
        node {
          _id
          listing_event_image {
            asset {
              _id
            }
          }
          title
          slug {
            current
          }
        }
      }
    }
    cardOutletPage: allSanityOutlets {
      edges {
        node {
          _id
          listing_image {
            asset {
              _id
            }
          }
          title
          slug {
            current
          }
          page_link
        }
      }
    }
  }
`;
const IndexPage = (props) => {
  // console.log(props);
  const { data, errors,location } = props;
  if (errors) {
    return <Errors errors={errors} />;
  }

  return <Page className="z-10" data={data} location={location}/>;
};

export default IndexPage;
