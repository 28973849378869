import React from "react";
// import GatsbyImageCustom from "../Image/GatsbyImageCustom";
// import Img from "../../assets/images/dubai.png";
import Img from "../../assets/images/coming-soon.png"
import { Link } from "gatsby";
import GatsbyImageCustom from "../Image/GatsbyImageCustom";

const CardInfoSection = (props) => {
  // console.log(props, "data");

  return (
    <>
      <div className="px-6 py-20 max-w-6xl mx-auto z-10 ">
        <div>
          <h1 className="text-center text-secondary-darkpurple-500 md:text-5xl text-3xl font-bold">
            {props.card_info_highligted}
          </h1>
        </div>
        <div className="flex flex-col md:flex-row justify-center items-center pt-14 flex-wrap lg:flex-nowrap">
          {props.cards_to_show.map((outlet) => {
            let tempOutlet = props.cardOutletPage.edges.find(
              (currEle) => currEle.node._id === outlet._ref
            );
            // console.log(tempOutlet,"tempOutlet")
            return (
              <div
                key={outlet._key}
                className="w-full md:w-80 h-full relative shadow-lg lg:shadow-1xl mb-10 md:mb-0 m-0 md:m-4 lg:m-8 rounded-lg"
              >
                <div className="rounded-lg">
                  <Link to={tempOutlet.node.page_link}>
                    <div className="overflow-hidden rounded-lg">
                      <GatsbyImageCustom
                        id={tempOutlet.node.listing_image.asset._id}
                      // <img
                      //   src="http://via.placeholder.com/320x392"
                        alt="img"
                        className="w-full filter brightness-90 hover:brightness-50 transition-all duration-1000 transform ease-in-out hover:scale-110 rounded-lg"
                      />
                    </div>
                  </Link>
                </div>
                <div
                  className="absolute bottom-5 w-4/5 bg-white text-left p-4 rounded-r-full z-20 shadow-1xl -left-1"
                  // style={{ backgroundColor: "#46005e" }}
                >
                  <div className="text-xl font-black text-secondary-linearGradient-300">
                    {tempOutlet.node.title}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="mt-16">
          <h1 className="text-center text-secondary-darkpurple-500 md:text-3xl text-xl font-bold">
            Upcoming Projects
          </h1>
        </div>
        <div className="flex flex-col md:flex-row justify-center items-center pt-14 flex-wrap lg:flex-nowrap ">
          {/* {props.cards_to_show.map((outlet) => {
            let tempOutlet = props.cardOutletPage.edges.find(
              (currEle) => currEle.node._id === outlet._ref
            );
            // console.log(tempOutlet,"tempOutlet")
            return ( */}
          <div
            // key={outlet._key}
            className="w-full md:w-80 h-full relative shadow-lg lg:shadow-1xl mb-10 md:mb-0 m-0 md:m-4 lg:m-8 rounded-lg"
          >
            <div className="">
              <Link to="/coming-soon/">
                <div className="overflow-hidden">
                  <img
                    src={Img}
                    // id={tempOutlet.node.listing_image.asset._id}
                    alt="img"
                    className="w-full filter brightness-90 hover:brightness-50 transition-all duration-1000 transform ease-in-out hover:scale-110 rounded-lg"
                  />
                </div>
              </Link>
            </div>
            <div
              className="absolute bottom-5 w-4/5 bg-white text-left p-4 rounded-r-full z-20 shadow-1xl -left-1"
              // style={{ backgroundColor: "#46005e" }}
            >
              <div className="text-xl font-black text-secondary-linearGradient-300">
                {/* {tempOutlet.node.title} */}
                DXB
              </div>
            </div>
          </div>
          {/* );
          })} */}
        </div>
      </div>
    </>
  );
};
export default CardInfoSection;
