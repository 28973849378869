import React from "react";
import Slider from "react-slick";
import "./home.css"
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
const TestimonialSection = (props) => {
  // console.log(props, "tt");
  // const isBrowser = typeof window !== "undefined";

  const settings = {
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3500,
    cssEase: "linear",
    pauseOnHover: true,
    arrows: false,
    centerMode: true,

    className: "centered-slider",

    responsive: [
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          autoplaySpeed: 3500,
          autoplay: true,
          // dots: true,
          centerPadding: "300px",
          className: "centered-slider",
        },
      },

      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          infinite: true,
          autoplaySpeed: 3500,
          autoplay: true,
          // centerPadding: "90px",
          // className: "centered-slider",
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          infinite: true,
          autoplaySpeed: 3500,
          autoplay: true,
          // centerPadding: "60px",
          // className: "centered-slider",
        },
      },
    ],
  };
  return (
    <>
      <div className="py-10 lg:py-20 z-10">
        <div className="testimonial-section-slider">
          <div className="">
            <h1 className="text-center text-secondary-darkpurple-500 lg:text-5xl text-3xl font-bold">
              {props.home_testimonial_highligted}
            </h1>
          </div>
          <Slider {...settings}>
            {props.home_testimonial_section.map((curr) => {
              return (
                <div className="lg:p-3 p-0 mt-10" key={curr._key}>
                  <div className="p-3 lg:p-6 py-8 lg:py-14 bg-secondary-darkblue-500 lg:bg-gray-100 text-white lg:text-black transition-all ease-in-out transform scale-90 card rounded-lg shadow-xl">
                    <div className=" ">
                      <div className=" space-y-8 text-center ">
                        <p className="flex justify-center items-center text-base lg:text-xl italic h-auto xl:h-40 min-h-full">
                          {curr.card_statistics_desc}
                        </p>
                        <h1 className="text-base lg:text-xl leading-6 lg:leading-none font-bold">
                          {curr.card_statistics_author}
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </>
  );
};

export default TestimonialSection;
