// import { Link } from "gatsby";
import { Link } from "gatsby";
import React from "react";
// import imgsrc from "../../assets/images/amethhyyst-jalwa.jpg";
// import ReactTooltip from "react-tooltip";
// import GatsbyImageCustom from "../Image/GatsbyImageCustom";
// import BG_Effect from "../../assets/images/Banner.png";
// import Banner from "../../assets/images/banner1.png";
// import Banner from "../../assets/images/parallax2.jpg";
// import Banner from "../../assets/images/home-1.png";
// import Banner from "../../assets/images/homepage.png";
// import GatsbyImageCustom from "../Image/GatsbyImageCustom";

const HeaderBannerSection = (props) => {
  // console.log(props, "jj");
  return (
    <>
      {/* <div className="relative bg-cover cursor-pointer">
        <a
          href="https://in.bookmyshow.com/events/amethhyyst-jalwaa-open-arena/ET00318129"
          data-tip
          data-for="global"
        >
          <img
            // style={{ height: "500px" }}
            src={imgsrc}
            alt="image header"
            className=" w-full filter object-cover brightness-100 hover:brightness-50"
          />
        </a>
        <ReactTooltip
          id="global"
          aria-haspopup="true"
          role="example"
          className="bg-secondary-golden-500"
        >
          <p className="text-secondary-darkpurple-500 font-semibold text-base">
            To Book Now Click Here
          </p>
        </ReactTooltip>
        <div
          className="block lg:hidden absolute m-auto"
          style={{ top: 169, left: 210 }}
        >
          <a href="https://in.bookmyshow.com/events/amethhyyst-jalwaa-open-arena/ET00318129">
            <button className="bg-secondary-golden-500 px-4 py-1 font-bold text-secondary-darkpurple-500 rounded hover:bg-secondary-golden-700 text-sm hover:text-secondary-darkblue-500 transition duration-500">
              Book Now
            </button>
          </a>
        </div>
      </div> */}
      {/* <div className=" bg-gradient-to-t from-secondary-linearGradient-600 to-secondary-linearGradient-700 "> */}
      <div
        className="z-10 flex flex-col items-center justify-center h-96 lg:h-750 w-full from-secondary-gray-700 via-blue-900 to-secondary-darkblue-500 bg-homepage-bg-img bg-auto md:bg-cover bg-fixed bg-center bg-origin-content bg-no-repeat object-contain"
        //
        // style={{
        //   backgroundImage: `linear-gradient(to top, #000000b6, #00000064),url(${Banner})`,
        //   backgroundSize: "cover",
        //   backgroundAttachment: "fixed",
        //   backgroundRepeat: "no-repeat",
        //   backgroundPosition: "20% 0%",
        // }}
      >
        <div className="block items-center max-w-6xl mx-auto absolute  lg:relative  ">
          <div className=" text-white font-extrabold">
            <div className="max-w-3xl text-2xl lg:text-6xl pb-10 uppercase">
              <span className="text-welcome flex justify-center items-center  font-bold text-white text-center pb-4">
                AVGSS Hospitality and
              </span>
              <span className="text-welcome flex justify-center items-center">
                Restaurants LLP
              </span>
            </div>
            <div className="text-base lg:text-lg text-white text-center max-w-xl mx-auto pb-10 px-2">
              Premium leisure spaces created for the youth, avid gourmands, and
              merrymakers
            </div>
            <div className="flex justify-center">
              <Link to="/about-us/">
              {/* <a href="https://wa.me/9167717641?text=">Send Message</a> */}
                <button className="custom-btn">
                  Know More
                </button>
              </Link>
            </div>
            {/* <div className="neons mt-7 md:text-xl ">
              <h1 className="text-xl lg:text-4xl">-A NIGHT LIFE-</h1>
            </div> */}
          </div>
        </div>
      </div>
      {/* <div className="relative">
        <div className="h-96 flex justify-between bg-transparent lg:bg-gray-200 w-full lg:h-auto ">
          <div className="block items-center max-w-6xl mx-auto absolute  lg:relative lg:flex  top-2/4 left-0  right-0 bottom-2/4 ">
            <div className=" text-black">
              <p className=" shiningText text-4xl lg:text-6xl ">
                Welcome <br /> to
                <br /> Ammethhyyst
              </p>
              <div className="neons mt-7 md:text-xl ">
                <h1 className="text-xl lg:text-4xl">-A NIGHT LIFE-</h1>
              </div>
            </div>
          </div>
          <div className="hidden lg:flex justify-end " style={{}}>
            <img src={Banner} className=" " />
          </div>
          <div
            className="block w-full bg-cover  bg-no-repeat lg:hidden"
            style={{ backgroundImage: `url(${Banner2})` }}
          ></div>
        </div>
      </div> */}

      {/* <div className="relative text-center text-white h-1/2 ">
        <img
          src={Banner}
          className="w-full bg-cover bg-center bg-no-repeat brightness-50"
        />

        <div className="text-center absolute top-2/4 bottom-2/4 left-2/4 ">
          <h1 style={{ fontSize: 50 }} className="typing-demo">
            Welcome To
          </h1>
          <p className="typing-demo">Ammethhyyst</p>
        </div>
      </div> */}

      {/* OLD CODE   */}
      {/* <div>
        <div>
          <img
            src={Banner}
            alt="img"
            // style={{objectFit:"contain"}}
            className="brightness-25 w- bg-cover"
          />
          <GatsbyImageCustom
            id={props.home_landing_image.asset._ref}
            alt={props.home_landing_altimage}
            className="md:block hidden  "
          />
        </div>
        <div className="md:hidden block bg-secondary-darkblue-500 lg:h-full h-screen">
          <img
            src={Banner}
            alt="img"
            // style={{objectFit:"contain"}}
            className=" opacity-60 w-full relative object-contain  lg:h-full h-screen bg-cover"
          />
        </div>

        <div className="max-w-6xl mx-auto">
          <div className="  pb-20 absolute lg:top-40 md:top-28 top-24 text-white px-6">
            <div className=" md:space-y-5 space-y-5 max-w-2xl">
              <h1 className=" font-bold lg:text-5xl md:text-2xl md:text-left  text-center text-xl ">
                {props.home_landing_title_highlighted}
              </h1>
              <p className=" lg:text-xl font-medium text-justify ">
                {props.home_landing_desc[0].children[0].text}
              </p>
              <p className="lg:text-xl text-justify font-medium">
                {props.home_landing_desc[1].children[0].text}
              </p>
            </div>
          </div>
        </div>
      </div> */}
      {/* </div> */}
    </>
  );
};

export default HeaderBannerSection;
