import React from "react";
import { Helmet } from "react-helmet";
import GraphQLErrorList from "../GraphQLErrorList/GraphQLErrorList";
// import AmnthyystInfoSection from "../Home/AmnthyystInfoSection";
import HeaderBannerSection from "../Home/HeaderBannerSection";
import CardInfoSection from "../Home/CardInfoSection";
import Layout from "../Layout/Layout";
// import MobileInfoSection from "../Home/MobileInfoSection";
// import NewsComponent from "../Home/NewsComponent";
import GalleryComponent from "../Home/GalleryComponent";
// import Layout from "../Layout/Layout";
import TestimonialSection from "../Home/TestimonialSection";
import StatisticsSection from "../Home/StatisticsSection";
// import AmethhyystEventSlider from "../Home/AmethhyystEventSlider";
import { graphql } from "gatsby";
// import SpecialEvents from "../Home/SpecialEvents";
// import BannerSlider from "../Home/BannerSlider";

export const query = graphql`
  query PageTemplateQuery($id: String!) {
    route: sanityPage(id: { eq: $id }) {
      slug {
        current
      }
      title
    }
  }
`;

const Page = (props) => {
  // console.log(props,"page")
  const { data, errors } = props;
  if (errors) {
    return (
      // <Layout location={props.location}>
      <GraphQLErrorList errors={errors} />
      // </Layout>
    );
  }
  // console.log(data.gallerypage.edges.node._id, "kk");

  const page = data.page || data.route.page;
  const content = (page._rawPagebuildersection || [])
    .filter((c) => !c.disabled)
    .map((c, i) => {
      let el = null;
      switch (c._type) {
        // case "caraousel_banner":
        //   el = <BannerSlider key={c._key} {...c} />;
        //   break;
        case "home_landing_section":
          el = <HeaderBannerSection key={c._key} {...c} />;
          break;
        // case "amnthyyst_info_section":
        //   el = <AmnthyystInfoSection key={c._key} {...c} />;
        //   break;
        // case "home_mobile_info_section":
        //   el = <MobileInfoSection key={c._key} {...c} />;
        //   break;
        case "home_card_info_section":
          el = <CardInfoSection key={c._key} {...c} {...data} />;
          break;
        // case "home_amnthyyst_events_slider":
        //   el = <AmethhyystEventSlider key={c._key} {...c} {...data} />;
        //   break;
        case "home_statistics_section":
          el = <StatisticsSection key={c._key} {...c} />;
          break;
        case "home_testimonial_section":
          el = <TestimonialSection key={c._key} {...c} />;
          break;
        // case "special_events":
        // el = <SpecialEvents key={c._key} {...c} />;
        // break;
        // case "caraousel_banner":
        //   el = <BannerSlider key={c._key} {...c} />;
        //   break;
        case "home_gallery_section":
          el = <GalleryComponent key={c._key} {...c} {...data} />;
          break;
        // case "home_review_news_section":
        //   el = <NewsComponent key={c._key} {...c} />;
        //   break;

        default:
          el = null;
      }
      return el;
    });

  return (
    <>
      <Layout location={props.location}>
        <Helmet>
          <title>{data.page.meta_title || data.page.title}</title>
          <meta
            property="og:title"
            content={data.page.meta_title || data.page.title}
          />
          <meta
            name="theme-color"
            content="#144a95"
            media="(prefers-color-scheme: light)"
          />
          <meta
            name="theme-color"
            content="#144a95"
            media="(prefers-color-scheme: dark)"
          />
          <meta name="theme-color" content="#144a95" />
          <meta
            property="twitter:title"
            content={data.page.meta_title || data.page.title}
          />{" "}
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"
          />
          <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests"></meta>
          <meta name="description" content={data.page.meta_desc} />
          <meta property="og:description" content={data.page.meta_desc} />
          <meta property="twitter:description" content={data.page.meta_desc} />
        </Helmet>
        <div className="z-10">{content}</div>
      </Layout>
    </>
  );
};

export default Page;
