import React from "react";
import GatsbyImageCustom from "../Image/GatsbyImageCustom";
// import { graphql, useStaticQuery } from "gatsby";
// import { getImage, GatsbyImage } from "gatsby-plugin-image";

const StatisticsSection = (props) => {
  // console.log(props, "ll");
  // const Image = useStaticQuery(graphql`
  //     allSanityImageAsset(
  //   filter: {_id: {eq: "image-b267a8ddf996a446d1fbdde64b6ed687707d0235-6001x1880-png"}}
  //   ) {
  //     nodes {
  //       localFile {
  //         publicURL
  //       }
  //     }
  //   }
  // }`);

  return (
    <>
      <div className="z-10">
        <div className=" text-white">
          <div className="relative">
            <GatsbyImageCustom
              id={props.home_statistics_image.asset._ref}
              alt={props.home_statistics_image_alt}
              className="md:block hidden"
            />
            <div className="text-center md:bg-transparent bg-secondary-darkblue-500 relative md:absolute top-0 right-0 left-0 bottom-0">
                <div className="max-w-6xl m-auto h-full flex flex-1 justify-center items-center flex-col py-10">
                  <h1 className="lg:text-5xl text-3xl font-bold relative ">
                    {props.home_statistics_highligted}
                  </h1>
                  <div className="grid grid-cols-1 gap-4 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 lg:px-24 px-6 ">
                    {props.home_statistics_section.map((xx) => {
                      return (
                        <div
                          className=" bg-black bg-opacity-30 p-5 mt-10 rounded shadow-1xl"
                          key={xx._key}
                        >
                          <h1 className="font-bold text-xl lg:text-3xl">
                            {xx.card_statistics_member}
                          </h1>
                          <p className="text-lg lg:text-2xl">
                            {xx.card_statistics_author}
                          </p>
                        </div>
                      );
                    })}
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StatisticsSection;
