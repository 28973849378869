import { Link } from "gatsby";
import React from "react";
import GatsbyImageCustom from "../Image/GatsbyImageCustom";

const GalleryComponent = (props) => {
  // console.log(props, "tt");

  return (
    <>
      <div className=" bg-secondary-gray-300 z-10">
        <div className="max-w-6xl mx-auto py-20 flex flex-col justify-center">
          <h1 className="text-center text-secondary-darkpurple-500 md:text-5xl text-3xl font-bold">
            {props.gallery_title_highligted}
          </h1>
          <div className="flex flex-col md:flex-row justify-center items-center pt-14 flex-wrap lg:flex-nowrap my-10 place-items-center gap-10">
            {props.gallery_to_show.map((gallery, idx) => {
              let tempGallery = props.gallerypage.edges.find(
                (currEle) => currEle.node._id === gallery._ref
              );
              return (
                <div
                  key={gallery._key}
                  className="cursor-pointer shadow-1xl w-80"
                >
                  {/* <div></div> */}
                  <Link to={`/gallery/${tempGallery.node.slug.current}`} className="">
                    {/* <div className="flex flex-col justify-center"> */}
                      <div className=" ">
                        <GatsbyImageCustom
                          id={tempGallery.node.cover_image.asset._id}
                          className="h-96 w-80 object-cover rounded-t-md"
                        />
                      </div>

                      <div>
                        <button className="bg-secondary-darkblue-500 rounded-b-md w-80 p-4 text-white">
                          {tempGallery.node.title}
                        </button>
                      </div>
                    {/* </div> */}
                  </Link>
                </div>
              );
            })}
          </div>

          <div className="text-center space-y-10">
            <p className="lg:text-2xl text-lg">{props.gallery_desc}</p>
            <div>
              <Link to={props.gallery_button_link}>
                <button className="custom-btn">
                  {props.gallery_button_title}
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default GalleryComponent;
